import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Profile, supabase } from '@/lib/supabase';
import { ProfileForm } from '@/components/profile/profile-form';
import { useAuthStore } from '@/lib/store';

export function ProfilePage() {
  const { username } = useParams();
  const [profile, setProfile] = useState<Profile | null>(null);
  const currentUser = useAuthStore((state) => state.user);
  const isOwnProfile = currentUser?.username === username;

  useEffect(() => {
    async function fetchProfile() {
      const { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('username', username)
        .single();

      if (!error) {
        setProfile(data);
      }
    }

    fetchProfile();
  }, [username]);

  if (!profile) return <div>Loading...</div>;

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="grid gap-8 md:grid-cols-[300px_1fr]">
        <aside className="space-y-6">
          <div className="rounded-lg border bg-white p-6">
            <img
              src={profile.avatar_url || 'https://via.placeholder.com/150'}
              alt={profile.full_name}
              className="mx-auto h-32 w-32 rounded-full object-cover"
            />
            <h1 className="mt-4 text-center text-2xl font-bold">{profile.full_name}</h1>
            <p className="mt-2 text-center text-gray-600">@{profile.username}</p>
          </div>

          <div className="rounded-lg border bg-white p-6">
            <h2 className="font-semibold">Expertise</h2>
            <div className="mt-2 flex flex-wrap gap-2">
              {profile.expertise.map((skill) => (
                <span
                  key={skill}
                  className="rounded-full bg-blue-100 px-3 py-1 text-sm text-blue-800"
                >
                  {skill}
                </span>
              ))}
            </div>
          </div>
        </aside>

        <main className="rounded-lg border bg-white p-6">
          {isOwnProfile ? (
            <ProfileForm profile={profile} />
          ) : (
            <div className="prose max-w-none">
              <h2 className="text-xl font-semibold">About</h2>
              <p>{profile.bio}</p>
            </div>
          )}
        </main>
      </div>
    </div>
  );
}