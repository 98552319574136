import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import { Job } from '@/types/job';
import { Button } from '@/components/ui/button';
import { Building2, MapPin, Calendar, Briefcase } from 'lucide-react';
import toast from 'react-hot-toast';

export function JobDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [job, setJob] = useState<Job | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchJob = async () => {
      try {
        const { data, error } = await supabase
          .from('jobs')
          .select(`
            *,
            company:companies(*)
          `)
          .eq('id', id)
          .single();

        if (error) throw error;
        setJob(data);
      } catch (error) {
        console.error('Error fetching job:', error);
        toast.error('Failed to load job details');
      } finally {
        setLoading(false);
      }
    };

    fetchJob();
  }, [id]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 pt-16">
        <div className="container mx-auto px-4 py-8">
          <div className="animate-pulse space-y-4">
            <div className="h-8 w-2/3 rounded bg-gray-200" />
            <div className="h-4 w-1/3 rounded bg-gray-200" />
            <div className="h-32 rounded bg-gray-200" />
          </div>
        </div>
      </div>
    );
  }

  if (!job) {
    return (
      <div className="min-h-screen bg-gray-50 pt-16">
        <div className="container mx-auto px-4 py-8">
          <div className="text-center">
            <h1 className="text-2xl font-bold">Job not found</h1>
            <Button
              onClick={() => navigate('/jobs')}
              className="mt-4"
            >
              Back to Jobs
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      <div className="container mx-auto px-4 py-8">
        <div className="grid gap-8 lg:grid-cols-[1fr_300px]">
          <main>
            <div className="rounded-lg border bg-white p-8 shadow-sm">
              <div className="flex items-start gap-6">
                {job.company.logo ? (
                  <img
                    src={job.company.logo}
                    alt={job.company.name}
                    className="h-16 w-16 rounded-lg object-cover"
                  />
                ) : (
                  <div className="flex h-16 w-16 items-center justify-center rounded-lg bg-blue-100">
                    <Building2 className="h-8 w-8 text-blue-600" />
                  </div>
                )}
                <div>
                  <h1 className="text-2xl font-bold">{job.title}</h1>
                  <p className="mt-1 text-lg text-gray-600">{job.company.name}</p>
                  <div className="mt-4 flex flex-wrap gap-4">
                    <div className="flex items-center gap-2 text-gray-600">
                      <MapPin size={20} />
                      <span>{job.location}</span>
                    </div>
                    <div className="flex items-center gap-2 text-gray-600">
                      <Briefcase size={20} />
                      <span>{job.type}</span>
                    </div>
                    <div className="flex items-center gap-2 text-gray-600">
                      <Calendar size={20} />
                      <span>Posted {new Date(job.postedAt).toLocaleDateString()}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-8 space-y-6">
                <section>
                  <h2 className="text-xl font-semibold">Description</h2>
                  <p className="mt-2 whitespace-pre-wrap text-gray-600">{job.description}</p>
                </section>

                <section>
                  <h2 className="text-xl font-semibold">Requirements</h2>
                  <ul className="mt-2 list-inside list-disc space-y-2 text-gray-600">
                    {job.requirements.map((req, index) => (
                      <li key={index}>{req}</li>
                    ))}
                  </ul>
                </section>

                <section>
                  <h2 className="text-xl font-semibold">Responsibilities</h2>
                  <ul className="mt-2 list-inside list-disc space-y-2 text-gray-600">
                    {job.responsibilities.map((resp, index) => (
                      <li key={index}>{resp}</li>
                    ))}
                  </ul>
                </section>

                <section>
                  <h2 className="text-xl font-semibold">Required Skills</h2>
                  <div className="mt-2 flex flex-wrap gap-2">
                    {job.skills.map((skill, index) => (
                      <span
                        key={index}
                        className="rounded-full bg-blue-100 px-3 py-1 text-sm font-medium text-blue-800"
                      >
                        {skill}
                      </span>
                    ))}
                  </div>
                </section>

                {job.benefits.length > 0 && (
                  <section>
                    <h2 className="text-xl font-semibold">Benefits</h2>
                    <ul className="mt-2 list-inside list-disc space-y-2 text-gray-600">
                      {job.benefits.map((benefit, index) => (
                        <li key={index}>{benefit}</li>
                      ))}
                    </ul>
                  </section>
                )}
              </div>
            </div>
          </main>

          <aside className="space-y-6">
            <div className="rounded-lg border bg-white p-6 shadow-sm">
              <Button className="w-full">Apply Now</Button>
              <p className="mt-4 text-center text-sm text-gray-500">
                {job.applicants} people have applied
              </p>
            </div>

            <div className="rounded-lg border bg-white p-6 shadow-sm">
              <h2 className="font-semibold">Salary Range</h2>
              <p className="mt-2 text-xl font-bold text-gray-900">
                {job.salary.currency} {job.salary.min.toLocaleString()} - {job.salary.max.toLocaleString()}
              </p>
              <p className="mt-1 text-sm text-gray-500">per year</p>
            </div>
          </aside>
        </div>
      </div>
    </div>
  );
}