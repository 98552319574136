import { useState, useEffect } from 'react';
import { Job } from '@/types/job';
import { JobCard } from './job-card';
import { fetchJobs } from '@/lib/api/jobs';
import { isApiError } from '@/lib/utils/errors';
import { formatApiError } from '@/lib/utils/api-helpers';
import toast from 'react-hot-toast';

interface JobListProps {
  searchQuery: string;
  filters: {
    type: string[];
    level: string[];
    location: string[];
    salary?: {
      min: number;
      max: number;
    };
    remote?: boolean;
  };
}

export function JobList({ searchQuery, filters }: JobListProps) {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadJobs = async () => {
      try {
        setLoading(true);
        setError(null);
        const data = await fetchJobs({ searchQuery, filters });
        setJobs(data);
      } catch (err) {
        const errorMessage = formatApiError(err);
        setError(errorMessage);
        toast.error(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    loadJobs();
  }, [searchQuery, filters]);

  if (loading) {
    return (
      <div className="space-y-4">
        {[1, 2, 3].map((i) => (
          <div
            key={i}
            className="h-48 animate-pulse rounded-lg border bg-gray-100"
          />
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="rounded-lg border bg-red-50 p-4 text-red-800">
        <p>{error}</p>
        <button 
          onClick={() => window.location.reload()}
          className="mt-2 text-sm font-medium text-red-600 hover:text-red-500"
        >
          Try again
        </button>
      </div>
    );
  }

  if (jobs.length === 0) {
    return (
      <div className="rounded-lg border bg-white p-8 text-center">
        <p className="text-gray-600">No jobs found matching your criteria</p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {jobs.map((job) => (
        <JobCard key={job.id} job={job} />
      ))}
    </div>
  );
}