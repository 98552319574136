export enum NewsCategory {
  ALL = 'all',
  INDUSTRY_NEWS = 'industry-news',
  AI_RESEARCH = 'ai-research',
  TUTORIALS = 'tutorials',
  CASE_STUDIES = 'case-studies',
  EVENTS = 'events'
}

export interface NewsPost {
  id: string;
  title: string;
  content: string;
  author: {
    name: string;
    avatar: string;
    headline: string;
  };
  category: NewsCategory;
  image?: string;
  likes: number;
  comments: NewsComment[];
  createdAt: string;
}

export interface NewsComment {
  id: string;
  content: string;
  author: {
    name: string;
    avatar: string;
  };
  createdAt: string;
}