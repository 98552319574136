import { Button } from '../ui/button';
import { ForumCategory } from '@/types/forum';

interface ForumCategoriesProps {
  categories: ForumCategory[];
  selectedCategory: ForumCategory;
  onSelectCategory: (category: ForumCategory) => void;
}

export function ForumCategories({ 
  categories, 
  selectedCategory, 
  onSelectCategory 
}: ForumCategoriesProps) {
  return (
    <div className="flex gap-2 overflow-x-auto">
      {categories.map(category => (
        <Button
          key={category}
          variant={selectedCategory === category ? 'primary' : 'secondary'}
          onClick={() => onSelectCategory(category)}
          className="whitespace-nowrap capitalize"
        >
          {category.replace('-', ' ')}
        </Button>
      ))}
    </div>
  );
}