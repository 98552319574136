import { NewsPost } from '@/types/news';
import { NewsPostCard } from '../../post/news-post-card';

interface PostListProps {
  posts: NewsPost[];
}

export function PostList({ posts }: PostListProps) {
  if (posts.length === 0) {
    return (
      <div className="rounded-lg border bg-white p-8 text-center">
        <p className="text-gray-600">No posts found in this category</p>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {posts.map((post) => (
        <NewsPostCard key={post.id} post={post} />
      ))}
    </div>
  );
}