import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from '@/components/ui/button';
import { Building2, Globe, Mail, Phone } from 'lucide-react';
import { supabase } from '@/lib/supabase';
import toast from 'react-hot-toast';

const verificationSchema = z.object({
  companyName: z.string().min(2, 'Company name is required'),
  registrationNumber: z.string().min(2, 'Registration number is required'),
  website: z.string().url('Must be a valid URL'),
  contactEmail: z.string().email('Must be a valid email'),
  contactPhone: z.string().min(10, 'Must be a valid phone number'),
  address: z.string().min(10, 'Full address is required'),
  industry: z.string().min(2, 'Industry is required'),
  size: z.string().min(1, 'Company size is required'),
  description: z.string().min(100, 'Company description must be at least 100 characters'),
});

type VerificationFormData = z.infer<typeof verificationSchema>;

export function CompanyVerificationForm() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [documents, setDocuments] = useState<File[]>([]);

  const { register, handleSubmit, formState: { errors } } = useForm<VerificationFormData>({
    resolver: zodResolver(verificationSchema),
  });

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setDocuments(Array.from(e.target.files));
    }
  };

  const onSubmit = async (data: VerificationFormData) => {
    try {
      setIsSubmitting(true);

      // Upload verification documents
      const documentUrls = await Promise.all(
        documents.map(async (file) => {
          const { data: uploadData, error: uploadError } = await supabase.storage
            .from('verification-documents')
            .upload(`${Date.now()}-${file.name}`, file);

          if (uploadError) throw uploadError;
          return uploadData.path;
        })
      );

      // Submit verification request
      const { error } = await supabase
        .from('company_verifications')
        .insert({
          ...data,
          documents: documentUrls,
          status: 'pending',
          submitted_at: new Date().toISOString(),
        });

      if (error) throw error;

      toast.success('Verification request submitted successfully!');
    } catch (error) {
      toast.error('Failed to submit verification request');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
      <div className="grid gap-6 md:grid-cols-2">
        <div>
          <label className="block text-sm font-medium text-gray-700">Company Name</label>
          <div className="relative mt-1">
            <Building2 className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" />
            <input
              {...register('companyName')}
              className="w-full rounded-md border py-2 pl-10 pr-3"
            />
          </div>
          {errors.companyName && (
            <p className="mt-1 text-sm text-red-600">{errors.companyName.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Registration Number</label>
          <input
            {...register('registrationNumber')}
            className="mt-1 w-full rounded-md border p-2"
          />
          {errors.registrationNumber && (
            <p className="mt-1 text-sm text-red-600">{errors.registrationNumber.message}</p>
          )}
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Website</label>
        <div className="relative mt-1">
          <Globe className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" />
          <input
            {...register('website')}
            className="w-full rounded-md border py-2 pl-10 pr-3"
            placeholder="https://example.com"
          />
        </div>
        {errors.website && (
          <p className="mt-1 text-sm text-red-600">{errors.website.message}</p>
        )}
      </div>

      <div className="grid gap-6 md:grid-cols-2">
        <div>
          <label className="block text-sm font-medium text-gray-700">Contact Email</label>
          <div className="relative mt-1">
            <Mail className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" />
            <input
              {...register('contactEmail')}
              type="email"
              className="w-full rounded-md border py-2 pl-10 pr-3"
            />
          </div>
          {errors.contactEmail && (
            <p className="mt-1 text-sm text-red-600">{errors.contactEmail.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Contact Phone</label>
          <div className="relative mt-1">
            <Phone className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" />
            <input
              {...register('contactPhone')}
              className="w-full rounded-md border py-2 pl-10 pr-3"
            />
          </div>
          {errors.contactPhone && (
            <p className="mt-1 text-sm text-red-600">{errors.contactPhone.message}</p>
          )}
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Business Documents</label>
        <div className="mt-1">
          <input
            type="file"
            multiple
            accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
            onChange={handleFileChange}
            className="w-full rounded-md border p-2"
          />
          <p className="mt-1 text-sm text-gray-500">
            Upload registration certificates, licenses, or other relevant documents
          </p>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Company Description</label>
        <textarea
          {...register('description')}
          rows={4}
          className="mt-1 w-full rounded-md border p-2"
        />
        {errors.description && (
          <p className="mt-1 text-sm text-red-600">{errors.description.message}</p>
        )}
      </div>

      <Button type="submit" disabled={isSubmitting} className="w-full">
        {isSubmitting ? 'Submitting...' : 'Submit Verification Request'}
      </Button>
    </form>
  );
}