import { MessageItem } from './message-item';
import { Message } from './types';

interface MessageListProps {
  messages: Message[];
  onRead: (id: string) => void;
}

export function MessageList({ messages, onRead }: MessageListProps) {
  return (
    <div className="space-y-4">
      {messages.map((message) => (
        <MessageItem key={message.id} message={message} onRead={onRead} />
      ))}
    </div>
  );
}