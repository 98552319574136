import { Circle } from 'lucide-react';
import { Message } from './types';
import { formatDistanceToNow } from '@/lib/utils';

interface MessageItemProps {
  message: Message;
  onRead: (id: string) => void;
}

export function MessageItem({ message, onRead }: MessageItemProps) {
  return (
    <div
      className={`flex cursor-pointer gap-3 rounded-lg p-2 transition-colors ${
        !message.read ? 'bg-blue-50' : 'hover:bg-gray-50'
      }`}
      onClick={() => onRead(message.id)}
    >
      <img
        src={message.sender.avatar}
        alt={message.sender.name}
        className="h-10 w-10 rounded-full object-cover"
      />
      <div className="flex-1 overflow-hidden">
        <div className="flex items-center justify-between">
          <p className="font-medium">{message.sender.name}</p>
          <span className="text-xs text-gray-500">
            {formatDistanceToNow(new Date(message.timestamp))}
          </span>
        </div>
        <p className="truncate text-sm text-gray-600">
          {message.content}
        </p>
      </div>
      {!message.read && (
        <Circle className="h-2 w-2 flex-shrink-0 fill-blue-600 text-blue-600" />
      )}
    </div>
  );
}