import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from '@/components/ui/button';
import { Image, Link, Smile } from 'lucide-react';
import { useNewsStore, useAuthStore } from '@/lib/store';
import { NewsCategory } from '@/types/news';
import toast from 'react-hot-toast';

const postSchema = z.object({
  title: z.string().min(5, 'Title must be at least 5 characters'),
  content: z.string().min(10, 'Content must be at least 10 characters'),
  category: z.nativeEnum(NewsCategory),
});

type PostFormData = z.infer<typeof postSchema>;

export function CreateNewsPost() {
  const [isExpanded, setIsExpanded] = useState(false);
  const user = useAuthStore((state) => state.user);
  const createPost = useNewsStore((state) => state.createPost);

  const { register, handleSubmit, reset, formState: { errors } } = useForm<PostFormData>({
    resolver: zodResolver(postSchema),
    defaultValues: {
      category: NewsCategory.INDUSTRY_NEWS,
    },
  });

  const onSubmit = async (data: PostFormData) => {
    if (!user) {
      toast.error('You must be logged in to create a post');
      return;
    }

    createPost({
      title: data.title,
      content: data.content,
      category: data.category,
      author: {
        name: user.full_name,
        avatar: user.avatar_url || 'https://via.placeholder.com/150',
        headline: user.bio || '',
      },
    });

    reset();
    setIsExpanded(false);
    toast.success('Post created successfully!');
  };

  if (!user) return null;

  return (
    <div className="rounded-lg border bg-white p-6">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
        {/* Form fields */}
        <select {...register('category')} className="mt-1 w-full rounded-md border p-2">
          {Object.values(NewsCategory).map((category) => (
            <option key={category} value={category}>
              {formatCategory(category)}
            </option>
          ))}
        </select>
        {/* Other form fields */}
      </form>
    </div>
  );
}

function formatCategory(category: NewsCategory): string {
  return category.split('-').map(word => 
    word.charAt(0).toUpperCase() + word.slice(1)
  ).join(' ');
}