import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Profile } from './supabase';
import { NewsPost, NewsComment } from '@/types/news';
import { newsPosts } from '@/data/news-posts';

// Auth Store
interface AuthStore {
  user: Profile | null;
  setUser: (user: Profile | null) => void;
  signOut: () => void;
}

export const useAuthStore = create<AuthStore>((set) => ({
  user: null,
  setUser: (user) => set({ user }),
  signOut: () => set({ user: null }),
}));

// News Store
interface NewsStore {
  posts: NewsPost[];
  bookmarks: string[];
  createPost: (post: Omit<NewsPost, 'id' | 'likes' | 'comments' | 'createdAt'>) => void;
  likePost: (postId: string) => void;
  addComment: (postId: string, comment: Omit<NewsComment, 'id' | 'createdAt'>) => void;
  toggleBookmark: (postId: string) => void;
  isBookmarked: (postId: string) => boolean;
}

export const useNewsStore = create<NewsStore>()(
  persist(
    (set, get) => ({
      posts: newsPosts,
      bookmarks: [],
      createPost: (post) =>
        set((state) => ({
          posts: [
            {
              id: Date.now().toString(),
              ...post,
              likes: 0,
              comments: [],
              createdAt: new Date().toISOString(),
            },
            ...state.posts,
          ],
        })),
      likePost: (postId) =>
        set((state) => ({
          posts: state.posts.map((post) =>
            post.id === postId ? { ...post, likes: post.likes + 1 } : post
          ),
        })),
      addComment: (postId, comment) =>
        set((state) => ({
          posts: state.posts.map((post) =>
            post.id === postId
              ? {
                  ...post,
                  comments: [
                    ...post.comments,
                    {
                      id: Date.now().toString(),
                      createdAt: new Date().toISOString(),
                      ...comment,
                    },
                  ],
                }
              : post
          ),
        })),
      toggleBookmark: (postId) =>
        set((state) => ({
          bookmarks: state.bookmarks.includes(postId)
            ? state.bookmarks.filter(id => id !== postId)
            : [...state.bookmarks, postId]
        })),
      isBookmarked: (postId) => get().bookmarks.includes(postId),
    }),
    {
      name: 'news-storage',
      partialize: (state) => ({
        bookmarks: state.bookmarks,
      }),
    }
  )
);