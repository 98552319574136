import { NewsCategory } from '@/types/news';

interface CategorySelectorProps {
  selectedCategory: NewsCategory;
  onCategoryChange: (category: NewsCategory) => void;
}

export function CategorySelector({ selectedCategory, onCategoryChange }: CategorySelectorProps) {
  const categories: NewsCategory[] = ['all', 'industry-news', 'ai-research', 'tutorials', 'case-studies', 'events'];

  return (
    <div className="flex flex-wrap gap-2">
      {categories.map((category) => (
        <button
          key={category}
          onClick={() => onCategoryChange(category)}
          className={`rounded-full px-4 py-2 text-sm font-medium transition-colors ${
            selectedCategory === category
              ? 'bg-primary text-white'
              : 'bg-white text-gray-600 hover:bg-gray-50'
          }`}
        >
          {formatCategory(category)}
        </button>
      ))}
    </div>
  );
}

function formatCategory(category: NewsCategory): string {
  return category === 'all' 
    ? 'All Categories'
    : category.split('-').map(word => 
        word.charAt(0).toUpperCase() + word.slice(1)
      ).join(' ');
}