import { ApiError } from './errors';

interface ErrorResponse {
  message: string;
  code?: string;
  details?: string;
}

export function handleApiError(error: unknown): never {
  // Handle Supabase errors
  if (error && typeof error === 'object' && 'code' in error && 'message' in error) {
    const { message, code, details } = error as ErrorResponse;
    throw new ApiError(message, code?.toString(), details);
  }

  // Handle standard errors
  if (error instanceof Error) {
    throw new ApiError(error.message);
  }

  // Handle unknown errors
  throw new ApiError('An unexpected error occurred');
}

export function formatApiError(error: unknown): string {
  if (error instanceof ApiError) {
    return error.message;
  }
  
  if (error instanceof Error) {
    return error.message;
  }
  
  return 'An unexpected error occurred';
}