import { useState } from 'react';
import { Button } from '../ui/button';
import toast from 'react-hot-toast';

interface ForumReplyFormProps {
  onSubmit: (data: { content: string }) => void;
}

export function ForumReplyForm({ onSubmit }: ForumReplyFormProps) {
  const [content, setContent] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (content.trim().length < 10) {
      toast.error('Reply must be at least 10 characters long');
      return;
    }
    onSubmit({ content });
    setContent('');
    toast.success('Reply posted successfully!');
  };

  return (
    <form onSubmit={handleSubmit} id="reply-form" className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Your Reply
        </label>
        <textarea
          value={content}
          onChange={(e) => setContent(e.target.value)}
          className="mt-1 h-32 w-full rounded-md border p-2 focus:border-blue-500 focus:outline-none focus:ring-1"
          placeholder="Share your thoughts..."
        />
      </div>
      <div className="flex justify-end">
        <Button type="submit">Post Reply</Button>
      </div>
    </form>
  );
}