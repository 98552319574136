import { Brain, Sparkles, Bot, Cpu, Network, Users, MessageSquare, Rocket, Globe, Lock, Zap, Code } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { NeuralBackground } from '@/components/shared/neural-background';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '@/lib/store';

const features = [
  {
    icon: Bot,
    title: 'AI-Powered Matching',
    description: 'Connect with the perfect collaborators and opportunities through our advanced AI algorithms.',
  },
  {
    icon: Network,
    title: 'Global AI Network',
    description: 'Join a worldwide community of AI professionals, researchers, and innovators.',
  },
  {
    icon: Cpu,
    title: 'Project Showcase',
    description: 'Share and discover cutting-edge AI projects with automated insights and analysis.',
  },
  {
    icon: MessageSquare,
    title: 'Expert Forums',
    description: 'Engage in deep technical discussions with AI experts across all domains.',
  },
  {
    icon: Rocket,
    title: 'Career Growth',
    description: 'Access exclusive AI job opportunities and career advancement resources.',
  },
  {
    icon: Lock,
    title: 'Secure Collaboration',
    description: 'Enterprise-grade security for sharing sensitive AI research and projects.',
  },
];

const stats = [
  { label: 'Active AI Professionals', value: '150K+' },
  { label: 'AI Projects Shared', value: '75K+' },
  { label: 'Research Papers Discussed', value: '25K+' },
  { label: 'AI Models Deployed', value: '100K+' },
];

const highlights = [
  {
    title: 'AI Research Hub',
    description: 'Access and discuss the latest AI research papers, implementations, and breakthroughs.',
    icon: Code,
  },
  {
    title: 'Model Exchange',
    description: 'Share and discover pre-trained models, datasets, and implementation code.',
    icon: Cpu,
  },
  {
    title: 'Learning Resources',
    description: 'Comprehensive tutorials, courses, and hands-on workshops for all skill levels.',
    icon: Users,
  },
];

export function HomePage() {
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);

  const handleGetStarted = () => {
    navigate(user ? '/news' : '/auth');
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 text-white">
      <NeuralBackground />
      
      {/* Hero Section */}
      <section className="relative overflow-hidden py-20">
        <div className="container mx-auto px-4">
          <div className="mx-auto max-w-4xl text-center">
            <div className="mb-8 flex justify-center">
              <div className="rounded-full bg-blue-500/20 p-4 backdrop-blur-lg">
                <Brain className="h-16 w-16" />
              </div>
            </div>
            <h1 className="mb-6 text-4xl font-bold sm:text-6xl">
              Welcome to Connectianet
              <span className="relative ml-2 block text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-400">
                The Future of AI Innovation
              </span>
            </h1>
            <p className="mb-8 text-lg text-blue-100 sm:text-xl">
              Join the elite network of AI professionals where cutting-edge algorithms power every interaction,
              from job matching to knowledge sharing.
            </p>
            <div className="flex flex-col gap-4 sm:flex-row sm:justify-center">
              <Button 
                size="lg" 
                className="bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600"
                onClick={handleGetStarted}
              >
                <Sparkles className="mr-2 h-5 w-5" />
                {user ? 'Go to Dashboard' : 'Get Started'}
              </Button>
              <Button 
                size="lg" 
                variant="ghost" 
                className="border border-gray-700 hover:bg-gray-800"
                onClick={() => navigate('/forum')}
              >
                <Globe className="mr-2 h-5 w-5" />
                Explore Platform
              </Button>
            </div>
          </div>
        </div>
      </section>

      {/* Stats Section */}
      <section className="py-16">
        <div className="container">
          <div className="grid grid-cols-2 gap-8 sm:grid-cols-4">
            {stats.map((stat) => (
              <Card key={stat.label} className="bg-gray-800/50 border-gray-700 p-6 text-center backdrop-blur-sm">
                <div className="text-3xl font-bold text-blue-400">{stat.value}</div>
                <div className="mt-2 text-sm text-gray-300">{stat.label}</div>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Features Grid */}
      <section className="py-20">
        <div className="container">
          <h2 className="mb-12 text-center text-3xl font-bold">Platform Features</h2>
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
              <Card 
                key={feature.title}
                className="group bg-gray-800/50 border-gray-700 p-6 backdrop-blur-sm transition-all hover:-translate-y-1"
              >
                <feature.icon className="mb-4 h-8 w-8 text-blue-400 transition-transform group-hover:scale-110" />
                <h3 className="mb-2 text-xl font-semibold">{feature.title}</h3>
                <p className="text-gray-300">{feature.description}</p>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* Highlights Section */}
      <section className="py-20">
        <div className="container">
          <div className="grid gap-8 lg:grid-cols-3">
            {highlights.map((highlight) => (
              <Card 
                key={highlight.title}
                className="bg-gradient-to-br from-blue-900/50 to-purple-900/50 border-gray-700 p-8 backdrop-blur-sm"
              >
                <highlight.icon className="mb-4 h-10 w-10 text-blue-400" />
                <h3 className="mb-2 text-xl font-semibold">{highlight.title}</h3>
                <p className="text-gray-300">{highlight.description}</p>
              </Card>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20">
        <div className="container">
          <Card className="overflow-hidden border-gray-800 bg-gradient-to-r from-blue-900/50 to-purple-900/50 p-12 text-center backdrop-blur-lg">
            <h2 className="mb-4 text-4xl font-bold">Ready to Shape the Future of AI?</h2>
            <p className="mb-8 text-xl text-gray-300">
              Join the world's most innovative AI community and start collaborating today.
            </p>
            <Button 
              size="lg" 
              className="bg-gradient-to-r from-blue-500 to-purple-500 hover:from-blue-600 hover:to-purple-600"
              onClick={handleGetStarted}
            >
              <Zap className="mr-2 h-5 w-5" />
              {user ? 'Go to Dashboard' : 'Join Connectianet'}
            </Button>
          </Card>
        </div>
      </section>
    </div>
  );
}