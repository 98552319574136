import { Link } from 'react-router-dom';
import { Card } from '@/components/ui/card';
import { NewsPostHeader } from './news-post-header';
import { NewsPostActions } from './news-post-actions';
import { useNewsActions } from '../hooks/use-news-actions';
import { useNewsStore } from '@/lib/store';
import type { NewsPostCardProps } from './types';

export function NewsPostCard({ post }: NewsPostCardProps) {
  const { handleLike, handleComment, handleBookmark } = useNewsActions();
  const isBookmarked = useNewsStore((state) => state.isBookmarked(post.id));

  return (
    <Card className="overflow-hidden">
      <div className="p-6">
        <Link to={`/news/${post.id}`}>
          <NewsPostHeader
            title={post.title}
            author={post.author}
            createdAt={post.createdAt}
          />
        </Link>

        <div className="mt-4">
          <p className="text-gray-800">{post.content}</p>
          {post.image && (
            <img
              src={post.image}
              alt="Post content"
              className="mt-4 rounded-lg"
            />
          )}
        </div>

        <div className="mt-4">
          <NewsPostActions
            likes={post.likes}
            comments={post.comments.length}
            isBookmarked={isBookmarked}
            onLike={() => handleLike(post.id)}
            onComment={() => handleComment(post.id)}
            onShare={() => {}}
            onBookmark={() => handleBookmark(post.id)}
          />
        </div>
      </div>
    </Card>
  );
}