import { MessageCircle, ThumbsUp } from 'lucide-react';
import { Button } from '../ui/button';

interface ForumTopicMetaProps {
  likes: number;
  replies: number;
  onLike: () => void;
  onReply: () => void;
}

export function ForumTopicMeta({ 
  likes, 
  replies, 
  onLike, 
  onReply 
}: ForumTopicMetaProps) {
  return (
    <div className="mt-4 flex items-center gap-4">
      <Button variant="ghost" className="gap-2" onClick={onLike}>
        <ThumbsUp size={18} />
        <span>{likes}</span>
      </Button>
      <Button variant="ghost" className="gap-2" onClick={onReply}>
        <MessageCircle size={18} />
        <span>{replies}</span>
      </Button>
    </div>
  );
}