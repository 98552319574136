import { useState } from 'react';
import { ForumSearch } from './forum-search';
import { ForumCategories } from './forum-categories';
import { ForumTopic } from './forum-topic';
import { ForumCreateTopic } from './forum-create-topic';
import { forumTopics } from '@/data/forum-topics';
import { ForumCategory } from '@/types/forum';

export function ForumList() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<ForumCategory>('all');
  const [topics, setTopics] = useState(forumTopics);

  const filteredTopics = topics.filter(topic => {
    const matchesSearch = topic.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      topic.description.toLowerCase().includes(searchQuery.toLowerCase());
    const matchesCategory = selectedCategory === 'all' || topic.category === selectedCategory;
    return matchesSearch && matchesCategory;
  });

  const categories: ForumCategory[] = ['all', ...new Set(topics.map(topic => topic.category as ForumCategory))];

  const handleCreateTopic = (data: any) => {
    const newTopic = {
      id: (topics.length + 1).toString(),
      ...data,
      author: {
        name: 'Current User',
        avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=400',
      },
      replies: 0,
      likes: 0,
      lastActivity: 'Just now',
    };
    setTopics([newTopic, ...topics]);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold">AI Forum</h1>
        <p className="mt-2 text-gray-600">Join discussions on various AI topics with experts worldwide</p>
      </div>

      <div className="mb-6">
        <ForumCreateTopic categories={categories} onCreateTopic={handleCreateTopic} />
      </div>

      <div className="mb-6 flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
        <ForumSearch value={searchQuery} onChange={setSearchQuery} />
        <ForumCategories
          categories={categories}
          selectedCategory={selectedCategory}
          onSelectCategory={setSelectedCategory}
        />
      </div>

      <div className="space-y-4">
        {filteredTopics.length > 0 ? (
          filteredTopics.map((topic) => (
            <ForumTopic key={topic.id} {...topic} />
          ))
        ) : (
          <div className="text-center py-8">
            <p className="text-gray-600">No topics found matching your criteria</p>
          </div>
        )}
      </div>
    </div>
  );
}