import { Search, MapPin, DollarSign, Briefcase } from 'lucide-react';
import { Button } from '../ui/button';

interface JobSearchProps {
  value: string;
  onChange: (value: string) => void;
}

export function JobSearch({ value, onChange }: JobSearchProps) {
  return (
    <div className="rounded-lg border bg-white p-4 shadow-sm dark:bg-gray-800">
      <div className="grid gap-4 md:grid-cols-[1fr_auto_auto_auto]">
        <div className="relative">
          <Search className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" />
          <input
            type="search"
            placeholder="Search jobs by title, company, or keywords..."
            className="w-full rounded-lg border py-2 pl-10 pr-4 focus:border-primary focus:outline-none focus:ring-1"
            value={value}
            onChange={(e) => onChange(e.target.value)}
          />
        </div>

        <Button variant="ghost" className="gap-2">
          <MapPin className="h-5 w-5" />
          Location
        </Button>

        <Button variant="ghost" className="gap-2">
          <DollarSign className="h-5 w-5" />
          Salary
        </Button>

        <Button variant="ghost" className="gap-2">
          <Briefcase className="h-5 w-5" />
          Job Type
        </Button>
      </div>
    </div>
  );
}