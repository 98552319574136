import { useState } from 'react';
import { ThumbsUp } from 'lucide-react';
import { Button } from '../ui/button';
import { formatDistanceToNow } from '@/lib/utils';

interface ForumReplyProps {
  author: {
    name: string;
    avatar: string;
  };
  content: string;
  createdAt: string;
  likes: number;
}

export function ForumReply({ author, content, createdAt, likes: initialLikes }: ForumReplyProps) {
  const [likes, setLikes] = useState(initialLikes);

  return (
    <div className="rounded-lg border bg-white p-6">
      <div className="flex items-start gap-4">
        <img
          src={author.avatar}
          alt={author.name}
          className="h-10 w-10 rounded-full object-cover"
        />
        <div className="flex-1">
          <div className="flex items-center justify-between">
            <div>
              <h4 className="font-medium">{author.name}</h4>
              <p className="text-sm text-gray-500">
                {formatDistanceToNow(new Date(createdAt))}
              </p>
            </div>
            <Button
              variant="ghost"
              size="sm"
              className="gap-2"
              onClick={() => setLikes(prev => prev + 1)}
            >
              <ThumbsUp size={16} />
              <span>{likes}</span>
            </Button>
          </div>
          <p className="mt-2 text-gray-700">{content}</p>
        </div>
      </div>
    </div>
  );
}