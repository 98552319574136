import { useState } from 'react';
import { Link } from 'react-router-dom';
import { User, Settings, Shield, HelpCircle, LogOut } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { useAuthStore } from '@/lib/store';

const menuItems = [
  {
    label: 'Ver mi perfil',
    icon: User,
    href: '/profile',
  },
  {
    label: 'Configuración',
    icon: Settings,
    href: '/settings',
  },
  {
    label: 'Privacidad y seguridad',
    icon: Shield,
    href: '/privacy',
  },
  {
    label: 'Ayuda y soporte',
    icon: HelpCircle,
    href: '/help',
  },
];

export function ProfileDropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const user = useAuthStore((state) => state.user);
  const signOut = useAuthStore((state) => state.signOut);

  if (!user) return null;

  return (
    <div className="relative">
      <Button
        variant="ghost"
        size="sm"
        className="flex items-center gap-2"
        onClick={() => setIsOpen(!isOpen)}
      >
        <img
          src={user.avatar_url || 'https://via.placeholder.com/32'}
          alt={user.full_name}
          className="h-8 w-8 rounded-full object-cover"
        />
        <span className="hidden md:inline">{user.full_name}</span>
      </Button>

      {isOpen && (
        <div className="absolute right-0 top-full mt-2 w-56 rounded-lg border bg-white p-2 shadow-lg">
          <div className="border-b p-3">
            <p className="font-medium">{user.full_name}</p>
            <p className="text-sm text-gray-500">@{user.username}</p>
          </div>

          <div className="space-y-1 p-1">
            {menuItems.map((item) => (
              <Link
                key={item.href}
                to={item.href}
                className="flex items-center gap-2 rounded-md px-3 py-2 text-sm text-gray-700 hover:bg-gray-100"
                onClick={() => setIsOpen(false)}
              >
                <item.icon className="h-4 w-4" />
                {item.label}
              </Link>
            ))}

            <button
              onClick={() => {
                signOut();
                setIsOpen(false);
              }}
              className="flex w-full items-center gap-2 rounded-md px-3 py-2 text-sm text-red-600 hover:bg-red-50"
            >
              <LogOut className="h-4 w-4" />
              Cerrar sesión
            </button>
          </div>
        </div>
      )}
    </div>
  );
}