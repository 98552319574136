import { ForumTopic } from '@/types/forum';
import { ForumTopicMeta } from './forum-topic-meta';
import { useState } from 'react';
import { Share2 } from 'lucide-react';
import { Button } from '../ui/button';
import toast from 'react-hot-toast';

interface ForumTopicDetailProps {
  topic: ForumTopic;
}

export function ForumTopicDetail({ topic }: ForumTopicDetailProps) {
  const [likes, setLikes] = useState(topic.likes);
  const [replies, setReplies] = useState(topic.replies);

  const handleLike = () => {
    setLikes(prev => prev + 1);
    toast.success('Topic liked!');
  };

  const handleReply = () => {
    const replySection = document.querySelector('#reply-form');
    replySection?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleShare = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      toast.success('Link copied to clipboard!');
    } catch (error) {
      toast.error('Failed to copy link');
    }
  };

  return (
    <article className="rounded-lg border bg-white p-8 shadow-md">
      <div className="mb-6 flex items-center justify-between">
        <div className="flex items-center gap-4">
          <img
            src={topic.author.avatar}
            alt={topic.author.name}
            className="h-12 w-12 rounded-full object-cover"
          />
          <div>
            <h1 className="text-2xl font-bold">{topic.title}</h1>
            <p className="mt-1 text-sm text-gray-600">
              Posted by <span className="font-medium">{topic.author.name}</span>
            </p>
          </div>
        </div>
        <Button variant="ghost" onClick={handleShare}>
          <Share2 size={20} />
        </Button>
      </div>

      <div className="prose max-w-none">
        <p className="text-gray-800">{topic.description}</p>
      </div>

      <div className="mt-6 flex flex-wrap items-center gap-4">
        <span className="rounded-full bg-blue-100 px-3 py-1 text-sm font-medium text-blue-800">
          {topic.category}
        </span>
        {topic.tags.map((tag) => (
          <span
            key={tag}
            className="rounded-full bg-gray-100 px-3 py-1 text-sm text-gray-600"
          >
            {tag}
          </span>
        ))}
      </div>

      <div className="mt-6 flex items-center justify-between border-t pt-6">
        <ForumTopicMeta
          likes={likes}
          replies={replies}
          onLike={handleLike}
          onReply={handleReply}
        />
        <span className="text-sm text-gray-500">
          Last active: {topic.lastActivity}
        </span>
      </div>
    </article>
  );
}