import { TrendingTopics } from '../trending-topics';
import { TopContributors } from '../top-contributors';
import { Card } from '@/components/ui/card';
import type { NewsFeedSidebarProps } from './types';

export function NewsFeedSidebar({ className }: NewsFeedSidebarProps = {}) {
  return (
    <aside className={`space-y-6 ${className || ''}`}>
      <Card className="p-6">
        <TrendingTopics />
      </Card>
      <Card className="p-6">
        <TopContributors />
      </Card>
    </aside>
  );
}