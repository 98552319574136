import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ForumReply } from '@/components/forum/forum-reply';
import { ForumReplyForm } from '@/components/forum/forum-reply-form';
import { forumTopics } from '@/data/forum-topics';
import { ForumTopicDetail } from '@/components/forum/forum-topic-detail';

export function TopicPage() {
  const { id } = useParams();
  const topic = forumTopics.find(t => t.id === id);
  const [replies, setReplies] = useState<any[]>([]);

  if (!topic) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-900">Topic not found</h1>
        </div>
      </div>
    );
  }

  const handleAddReply = (replyData: any) => {
    setReplies([...replies, {
      id: Date.now().toString(),
      ...replyData,
      author: {
        name: 'Current User',
        avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?w=400',
      },
      createdAt: new Date().toISOString(),
      likes: 0,
    }]);
  };

  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      <div className="container mx-auto px-4 py-8">
        <ForumTopicDetail topic={topic} />
        
        <div className="mt-8">
          <h2 className="text-2xl font-bold">Replies</h2>
          <div className="mt-4 space-y-4">
            {replies.map((reply) => (
              <ForumReply key={reply.id} {...reply} />
            ))}
          </div>
          
          <div className="mt-8">
            <ForumReplyForm onSubmit={handleAddReply} />
          </div>
        </div>
      </div>
    </div>
  );
}