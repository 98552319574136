import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Navbar } from './components/layout/navbar';
import { HomePage } from './pages/home';
import { AuthPage } from './pages/auth';
import { ProfilePage } from './pages/profile';
import { NewsPage } from './pages/news';
import { ForumPage } from './pages/forum';
import { TopicPage } from './pages/topic';
import { ProfileSetupPage } from './pages/profile-setup';
import { EmailVerificationPage } from './pages/email-verification';
import { JobsPage } from './pages/jobs';
import { JobCreatePage } from './pages/jobs/create';
import { JobDetailPage } from './pages/jobs/[id]';
import { CompanyVerificationPage } from './pages/company/verification';
import { Toaster } from 'react-hot-toast';
import { useAuthStore } from './lib/store';

export default function App() {
  const user = useAuthStore((state) => state.user);

  return (
    <Router>
      <div className="min-h-screen bg-background">
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/auth" element={<AuthPage />} />
          <Route path="/profile/:username" element={<ProfilePage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/forum" element={<ForumPage />} />
          <Route path="/forum/topic/:id" element={<TopicPage />} />
          <Route path="/profile-setup" element={<ProfileSetupPage />} />
          <Route path="/verify-email" element={<EmailVerificationPage />} />
          <Route path="/jobs" element={<JobsPage />} />
          <Route path="/jobs/create" element={<JobCreatePage />} />
          <Route path="/jobs/:id" element={<JobDetailPage />} />
          <Route path="/company/verification" element={<CompanyVerificationPage />} />
        </Routes>
        <Toaster position="top-right" />
      </div>
    </Router>
  );
}