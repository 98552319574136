import { supabase } from '../supabase';
import { Job } from '@/types/job';
import { handleApiError } from '../utils/api-helpers';

interface FetchJobsParams {
  searchQuery?: string;
  filters?: {
    type?: string[];
    level?: string[];
    location?: string[];
    salary?: {
      min: number;
      max: number;
    };
    remote?: boolean;
  };
}

export async function fetchJobs(params: FetchJobsParams): Promise<Job[]> {
  try {
    let query = supabase
      .from('jobs')
      .select(`
        *,
        company:companies(*)
      `)
      .eq('status', 'published');

    // Apply filters
    if (params.searchQuery) {
      query = query.or(`
        title.ilike.%${params.searchQuery}%,
        description.ilike.%${params.searchQuery}%
      `);
    }

    if (params.filters?.type?.length) {
      query = query.in('type', params.filters.type);
    }

    if (params.filters?.level?.length) {
      query = query.in('level', params.filters.level);
    }

    if (params.filters?.location?.length) {
      query = query.in('location', params.filters.location);
    }

    if (params.filters?.salary) {
      query = query
        .gte('salary->min', params.filters.salary.min)
        .lte('salary->max', params.filters.salary.max);
    }

    if (params.filters?.remote) {
      query = query.eq('remote', true);
    }

    const { data, error } = await query;

    if (error) {
      throw error;
    }

    return data as Job[];
  } catch (error) {
    throw handleApiError(error);
  }
}

export async function fetchJobById(id: string): Promise<Job> {
  try {
    const { data, error } = await supabase
      .from('jobs')
      .select(`
        *,
        company:companies(*)
      `)
      .eq('id', id)
      .single();

    if (error) {
      throw error;
    }

    return data as Job;
  } catch (error) {
    throw handleApiError(error);
  }
}