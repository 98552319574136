import { useState } from 'react';
import { MessageSquare } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { MessageList } from './message-list';
import { Message } from './types';

const initialMessages: Message[] = [
  {
    id: "1",
    sender: {
      name: "Sarah Chen",
      avatar: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=400",
    },
    content: "Hey, I saw your post about AI models...",
    timestamp: new Date(Date.now() - 1000 * 60 * 15).toISOString(),
    read: false,
  },
  {
    id: "2",
    sender: {
      name: "Michael Wong",
      avatar: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=400",
    },
    content: "Thanks for connecting! Would love to discuss more...",
    timestamp: new Date(Date.now() - 1000 * 60 * 45).toISOString(),
    read: false,
  },
];

export function MessageDropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState<Message[]>(initialMessages);

  const unreadCount = messages.filter(msg => !msg.read).length;

  const handleMarkAsRead = (messageId: string) => {
    setMessages(messages.map(msg => 
      msg.id === messageId ? { ...msg, read: true } : msg
    ));
  };

  return (
    <div className="relative">
      <Button
        variant="ghost"
        size="sm"
        className="relative"
        onClick={() => setIsOpen(!isOpen)}
      >
        <MessageSquare className="h-5 w-5" />
        {unreadCount > 0 && (
          <span className="absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-xs text-white">
            {unreadCount}
          </span>
        )}
      </Button>

      {isOpen && (
        <div className="absolute right-0 top-full mt-2 w-80 rounded-lg border bg-white p-4 shadow-lg">
          <div className="mb-4 flex items-center justify-between">
            <h3 className="font-semibold">Messages</h3>
            {unreadCount > 0 && (
              <span className="text-sm text-gray-500">{unreadCount} new</span>
            )}
          </div>

          <MessageList messages={messages} onRead={handleMarkAsRead} />

          <Button
            variant="ghost"
            className="mt-4 w-full text-sm text-blue-600 hover:bg-blue-50"
          >
            View All Messages
          </Button>
        </div>
      )}
    </div>
  );
}