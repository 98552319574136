import { CompanyVerificationForm } from '@/components/company/verification-form';

export function CompanyVerificationPage() {
  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      <div className="container mx-auto px-4 py-8">
        <div className="mx-auto max-w-2xl">
          <div className="mb-8 text-center">
            <h1 className="text-3xl font-bold">Company Verification</h1>
            <p className="mt-2 text-gray-600">
              Complete the verification process to start posting jobs and accessing employer features
            </p>
          </div>

          <div className="rounded-lg border bg-white p-6 shadow-sm">
            <CompanyVerificationForm />
          </div>
        </div>
      </div>
    </div>
  );
}