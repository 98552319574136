import { useCallback } from 'react';
import { useNewsStore } from '@/lib/store';
import toast from 'react-hot-toast';

export function useNewsActions() {
  const { likePost, addComment, toggleBookmark } = useNewsStore();

  const handleLike = useCallback((postId: string) => {
    likePost(postId);
    toast.success('Post liked!');
  }, [likePost]);

  const handleComment = useCallback((postId: string, content: string) => {
    addComment(postId, {
      content,
      author: {
        name: 'Current User',
        avatar: 'https://via.placeholder.com/150',
      },
    });
    toast.success('Comment added!');
  }, [addComment]);

  const handleBookmark = useCallback((postId: string) => {
    toggleBookmark(postId);
    toast.success('Bookmark updated!');
  }, [toggleBookmark]);

  return {
    handleLike,
    handleComment,
    handleBookmark,
  };
}