import { ForumTopic as ForumTopicType } from '@/types/forum';
import { ForumTopicMeta } from './forum-topic-meta';
import { useState } from 'react';
import toast from 'react-hot-toast';

export function ForumTopic({
  title,
  description,
  author,
  category,
  tags,
  replies: initialReplies,
  likes: initialLikes,
  lastActivity,
}: ForumTopicType) {
  const [likes, setLikes] = useState(initialLikes);
  const [replies, setReplies] = useState(initialReplies);

  const handleLike = () => {
    setLikes(prev => prev + 1);
    toast.success('Topic liked!');
  };

  const handleReply = () => {
    setReplies(prev => prev + 1);
    toast.success('Reply added!');
  };

  return (
    <div className="rounded-lg border bg-white p-6 shadow-sm transition-shadow hover:shadow-md">
      <div className="flex items-start gap-4">
        <img
          src={author.avatar}
          alt={author.name}
          className="h-12 w-12 rounded-full object-cover"
        />
        <div className="flex-1">
          <div className="flex items-start justify-between">
            <div>
              <h3 className="text-lg font-semibold hover:text-blue-600 cursor-pointer">
                {title}
              </h3>
              <p className="mt-1 text-sm text-gray-600">
                Posted by <span className="font-medium hover:text-blue-600 cursor-pointer">
                  {author.name}
                </span>
              </p>
            </div>
            <span className="text-sm text-gray-500">Last active: {lastActivity}</span>
          </div>
          <p className="mt-3 text-gray-600">{description}</p>
          <div className="mt-4 flex flex-wrap items-center gap-4">
            <span className="rounded-full bg-blue-100 px-3 py-1 text-sm font-medium text-blue-800">
              {category}
            </span>
            {tags.map((tag) => (
              <span
                key={tag}
                className="rounded-full bg-gray-100 px-3 py-1 text-sm text-gray-600 cursor-pointer hover:bg-gray-200"
              >
                {tag}
              </span>
            ))}
          </div>
          <ForumTopicMeta
            likes={likes}
            replies={replies}
            onLike={handleLike}
            onReply={handleReply}
          />
        </div>
      </div>
    </div>
  );
}