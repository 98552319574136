import { Card } from '@/components/ui/card';
import { trendingTopics } from '../data/trending';

export function TrendingTopics() {
  return (
    <Card className="p-6">
      <h2 className="text-lg font-semibold">Trending Topics</h2>
      <div className="mt-4 space-y-3">
        {trendingTopics.map((topic) => (
          <div key={topic.name} className="flex items-center gap-3">
            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-blue-100 text-blue-600">
              #{topic.rank}
            </div>
            <div>
              <p className="font-medium text-gray-900">{topic.name}</p>
              <p className="text-sm text-gray-500">{topic.posts} posts</p>
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
}