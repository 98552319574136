import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from '../ui/button';
import { Card } from '../ui/card';
import { signIn, signUp } from '@/lib/api/auth';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '@/lib/store';
import { User, Mail, Lock, Building2 } from 'lucide-react';
import toast from 'react-hot-toast';

const authSchema = z.object({
  email: z.string().email('Invalid email address'),
  password: z.string().min(8, 'Password must be at least 8 characters'),
  role: z.enum(['individual', 'company']),
  fullName: z.string().min(2, 'Full name is required').optional(),
  companyName: z.string().min(2, 'Company name is required').optional(),
}).refine((data) => {
  if (data.role === 'individual') {
    return !!data.fullName;
  }
  return !!data.companyName;
}, {
  message: 'Please provide required profile information',
  path: ['fullName'],
});

type AuthFormData = z.infer<typeof authSchema>;

export function AuthForm() {
  const [isLogin, setIsLogin] = useState(true);
  const [role, setRole] = useState<'individual' | 'company'>('individual');
  const navigate = useNavigate();
  const setUser = useAuthStore((state) => state.setUser);

  const { register, handleSubmit, formState: { errors, isSubmitting } } = useForm<AuthFormData>({
    resolver: zodResolver(authSchema),
    defaultValues: {
      role: 'individual',
    },
  });

  const onSubmit = async (data: AuthFormData) => {
    try {
      if (isLogin) {
        const { data: authData } = await signIn(data);
        if (authData.user) {
          setUser(authData.user);
          toast.success('Welcome back to Connectianet!');
          navigate('/');
        }
      } else {
        await signUp(data);
        toast.success('Please check your email to verify your account');
        navigate('/verify-email');
      }
    } catch (error) {
      toast.error(error instanceof Error ? error.message : 'Authentication failed');
    }
  };

  return (
    <Card className="w-full max-w-md p-8 space-y-8">
      <div className="text-center">
        <h2 className="text-2xl font-bold">
          {isLogin ? 'Welcome Back' : 'Join Connectianet'}
        </h2>
        <p className="mt-2 text-gray-600">
          {isLogin ? "Don't have an account?" : 'Already have an account?'}
          <button
            onClick={() => setIsLogin(!isLogin)}
            className="ml-2 text-primary hover:underline"
          >
            {isLogin ? 'Sign Up' : 'Sign In'}
          </button>
        </p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        {!isLogin && (
          <div className="flex gap-4">
            <Button
              type="button"
              variant={role === 'individual' ? 'primary' : 'secondary'}
              className="flex-1"
              onClick={() => setRole('individual')}
            >
              <User className="mr-2 h-5 w-5" />
              Professional
            </Button>
            <Button
              type="button"
              variant={role === 'company' ? 'primary' : 'secondary'}
              className="flex-1"
              onClick={() => setRole('company')}
            >
              <Building2 className="mr-2 h-5 w-5" />
              Company
            </Button>
          </div>
        )}

        {!isLogin && role === 'individual' && (
          <div>
            <label className="block text-sm font-medium text-gray-700">Full Name</label>
            <input
              {...register('fullName')}
              className="mt-1 w-full rounded-md border p-2"
              placeholder="Enter your full name"
            />
            {errors.fullName && (
              <p className="mt-1 text-sm text-red-600">{errors.fullName.message}</p>
            )}
          </div>
        )}

        {!isLogin && role === 'company' && (
          <div>
            <label className="block text-sm font-medium text-gray-700">Company Name</label>
            <input
              {...register('companyName')}
              className="mt-1 w-full rounded-md border p-2"
              placeholder="Enter company name"
            />
            {errors.companyName && (
              <p className="mt-1 text-sm text-red-600">{errors.companyName.message}</p>
            )}
          </div>
        )}

        <div>
          <label className="block text-sm font-medium text-gray-700">Email</label>
          <div className="relative mt-1">
            <Mail className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" />
            <input
              {...register('email')}
              type="email"
              className="w-full rounded-md border py-2 pl-10 pr-3"
              placeholder="Enter your email"
            />
          </div>
          {errors.email && (
            <p className="mt-1 text-sm text-red-600">{errors.email.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Password</label>
          <div className="relative mt-1">
            <Lock className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" />
            <input
              {...register('password')}
              type="password"
              className="w-full rounded-md border py-2 pl-10 pr-3"
              placeholder="Enter your password"
            />
          </div>
          {errors.password && (
            <p className="mt-1 text-sm text-red-600">{errors.password.message}</p>
          )}
        </div>

        <input type="hidden" {...register('role')} value={role} />

        <Button 
          type="submit" 
          className="w-full"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Please wait...' : (isLogin ? 'Sign In' : 'Create Account')}
        </Button>
      </form>
    </Card>
  );
}