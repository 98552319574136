import { Search } from 'lucide-react';

interface ForumSearchProps {
  value: string;
  onChange: (value: string) => void;
}

export function ForumSearch({ value, onChange }: ForumSearchProps) {
  return (
    <div className="relative flex-1">
      <Search className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" />
      <input
        type="search"
        placeholder="Search topics..."
        className="w-full rounded-lg border py-2 pl-10 pr-4 focus:border-blue-500 focus:outline-none focus:ring-1"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
}