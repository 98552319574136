export enum JobType {
  FULL_TIME = 'full-time',
  PART_TIME = 'part-time',
  CONTRACT = 'contract',
  INTERNSHIP = 'internship',
  REMOTE = 'remote',
}

export enum ExperienceLevel {
  ENTRY = 'entry',
  MID = 'mid',
  SENIOR = 'senior',
  LEAD = 'lead',
  EXECUTIVE = 'executive',
}

export interface Salary {
  min: number;
  max: number;
  currency: string;
}

export interface CompanyProfile {
  id: string;
  name: string;
  logo?: string;
  industry: string;
  size: string;
  location: string;
  description: string;
  website?: string;
}

export type JobStatus = 'draft' | 'published' | 'closed' | 'archived';

export interface Job {
  id: string;
  title: string;
  company: CompanyProfile;
  location: string;
  type: JobType;
  level: ExperienceLevel;
  salary: Salary;
  description: string;
  requirements: string[];
  responsibilities: string[];
  skills: string[];
  benefits: string[];
  postedAt: string;
  deadline?: string;
  status: JobStatus;
  applicants: number;
}