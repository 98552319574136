import { NewsPost, NewsCategory } from '@/types/news';

export function filterPostsByCategory(posts: NewsPost[], category: NewsCategory): NewsPost[] {
  return category === 'all' 
    ? posts 
    : posts.filter(post => post.category === category);
}

export function filterPostsBySearch(posts: NewsPost[], query: string): NewsPost[] {
  const searchTerm = query.toLowerCase();
  return posts.filter(post => 
    post.title.toLowerCase().includes(searchTerm) ||
    post.content.toLowerCase().includes(searchTerm)
  );
}