import { Card } from '@/components/ui/card';
import { topContributors } from '../data/trending';

export function TopContributors() {
  return (
    <Card className="p-6">
      <h2 className="text-lg font-semibold">Top Contributors</h2>
      <div className="mt-4 space-y-4">
        {topContributors.map((contributor) => (
          <div key={contributor.name} className="flex items-center gap-3">
            <img
              src={contributor.avatar}
              alt={contributor.name}
              className="h-10 w-10 rounded-full object-cover"
            />
            <div>
              <p className="font-medium text-gray-900">{contributor.name}</p>
              <p className="text-sm text-gray-500">{contributor.contributions} contributions</p>
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
}