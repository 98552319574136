import { Job } from '@/types/job';
import { Building2, MapPin, Calendar, DollarSign, Briefcase, Users } from 'lucide-react';
import { Button } from '../ui/button';
import { Card } from '../ui/card';
import { Link } from 'react-router-dom';

interface JobCardProps {
  job: Job;
}

export function JobCard({ job }: JobCardProps) {
  return (
    <Card className="group overflow-hidden p-6 transition-all hover:scale-[1.02]" glow>
      <div className="flex items-start gap-4">
        {job.company.logo ? (
          <img
            src={job.company.logo}
            alt={job.company.name}
            className="h-16 w-16 rounded-lg object-cover"
          />
        ) : (
          <div className="flex h-16 w-16 items-center justify-center rounded-lg bg-primary/10">
            <Building2 className="h-8 w-8 text-primary" />
          </div>
        )}

        <div className="flex-1">
          <div className="flex items-start justify-between">
            <div>
              <Link 
                to={`/jobs/${job.id}`}
                className="text-xl font-semibold hover:text-primary"
              >
                {job.title}
              </Link>
              <p className="mt-1 text-gray-600 dark:text-gray-400">
                {job.company.name}
              </p>
            </div>
            <Button variant="futuristic">Apply Now</Button>
          </div>

          <div className="mt-4 flex flex-wrap items-center gap-4">
            <div className="flex items-center gap-2 text-gray-600">
              <MapPin className="h-4 w-4" />
              <span>{job.location}</span>
            </div>
            <div className="flex items-center gap-2 text-gray-600">
              <DollarSign className="h-4 w-4" />
              <span>{job.salary.min.toLocaleString()} - {job.salary.max.toLocaleString()} {job.salary.currency}</span>
            </div>
            <div className="flex items-center gap-2 text-gray-600">
              <Briefcase className="h-4 w-4" />
              <span>{job.type}</span>
            </div>
            <div className="flex items-center gap-2 text-gray-600">
              <Users className="h-4 w-4" />
              <span>{job.level}</span>
            </div>
          </div>

          <div className="mt-4 flex flex-wrap gap-2">
            {job.skills.slice(0, 5).map((skill) => (
              <span
                key={skill}
                className="rounded-full bg-primary/10 px-3 py-1 text-sm text-primary"
              >
                {skill}
              </span>
            ))}
            {job.skills.length > 5 && (
              <span className="rounded-full bg-gray-100 px-3 py-1 text-sm text-gray-600 dark:bg-gray-800 dark:text-gray-400">
                +{job.skills.length - 5} more
              </span>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
}