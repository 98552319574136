import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import { useAuthStore } from '@/lib/store';
import toast from 'react-hot-toast';
import { Building2, Briefcase, GraduationCap, MapPin } from 'lucide-react';

const profileSchema = z.object({
  headline: z.string().min(10, 'Professional headline is required'),
  location: z.string().min(2, 'Location is required'),
  experience: z.array(z.object({
    title: z.string().min(2, 'Job title is required'),
    company: z.string().min(2, 'Company name is required'),
    startDate: z.string(),
    endDate: z.string().optional(),
    current: z.boolean(),
    description: z.string().optional(),
  })),
  education: z.array(z.object({
    institution: z.string().min(2, 'Institution name is required'),
    degree: z.string().min(2, 'Degree is required'),
    field: z.string().min(2, 'Field of study is required'),
    startDate: z.string(),
    endDate: z.string().optional(),
    current: z.boolean(),
  })),
  skills: z.array(z.string()).min(1, 'At least one skill is required'),
});

type ProfileFormData = z.infer<typeof profileSchema>;

export function ProfileSetupPage() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [skillInput, setSkillInput] = useState('');
  const user = useAuthStore((state) => state.user);
  const setUser = useAuthStore((state) => state.setUser);

  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm<ProfileFormData>({
    resolver: zodResolver(profileSchema),
    defaultValues: {
      experience: [{ current: false }],
      education: [{ current: false }],
      skills: [],
    },
  });

  const skills = watch('skills');

  const addSkill = () => {
    if (skillInput.trim() && !skills.includes(skillInput.trim())) {
      setValue('skills', [...skills, skillInput.trim()]);
      setSkillInput('');
    }
  };

  const removeSkill = (skillToRemove: string) => {
    setValue('skills', skills.filter(skill => skill !== skillToRemove));
  };

  const onSubmit = async (data: ProfileFormData) => {
    try {
      const { error } = await supabase
        .from('profiles')
        .update({
          ...data,
          setup_completed: true,
        })
        .eq('id', user?.id);

      if (error) throw error;

      setUser({
        ...user!,
        profile: {
          ...user!.profile,
          ...data,
          setup_completed: true,
        },
      });

      toast.success('Profile setup completed!');
      navigate('/dashboard');
    } catch (error) {
      toast.error('Failed to save profile');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      <div className="container mx-auto px-4 py-8">
        <div className="mx-auto max-w-2xl">
          <div className="mb-8 text-center">
            <h1 className="text-3xl font-bold">Complete Your Profile</h1>
            <p className="mt-2 text-gray-600">
              Help us personalize your experience and connect you with the right opportunities
            </p>
          </div>

          <div className="rounded-lg border bg-white p-6 shadow-sm">
            <div className="mb-6 flex justify-between">
              {[1, 2, 3].map((stepNumber) => (
                <div
                  key={stepNumber}
                  className={`flex h-8 w-8 items-center justify-center rounded-full ${
                    step >= stepNumber ? 'bg-blue-600 text-white' : 'bg-gray-200'
                  }`}
                >
                  {stepNumber}
                </div>
              ))}
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              {step === 1 && (
                <div className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Professional Headline
                    </label>
                    <input
                      {...register('headline')}
                      className="mt-1 w-full rounded-md border p-2"
                      placeholder="e.g., Senior AI Engineer at Tech Corp"
                    />
                    {errors.headline && (
                      <p className="mt-1 text-sm text-red-600">{errors.headline.message}</p>
                    )}
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Location
                    </label>
                    <div className="relative mt-1">
                      <MapPin className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" />
                      <input
                        {...register('location')}
                        className="w-full rounded-md border py-2 pl-10 pr-3"
                        placeholder="City, Country"
                      />
                    </div>
                    {errors.location && (
                      <p className="mt-1 text-sm text-red-600">{errors.location.message}</p>
                    )}
                  </div>

                  <Button type="button" onClick={() => setStep(2)} className="w-full">
                    Next: Experience
                  </Button>
                </div>
              )}

              {step === 2 && (
                <div className="space-y-6">
                  <div>
                    <h3 className="flex items-center gap-2 text-lg font-semibold">
                      <Briefcase className="h-5 w-5" />
                      Work Experience
                    </h3>
                    {/* Experience fields */}
                  </div>

                  <div>
                    <h3 className="flex items-center gap-2 text-lg font-semibold">
                      <GraduationCap className="h-5 w-5" />
                      Education
                    </h3>
                    {/* Education fields */}
                  </div>

                  <div className="flex gap-4">
                    <Button type="button" variant="secondary" onClick={() => setStep(1)}>
                      Back
                    </Button>
                    <Button type="button" onClick={() => setStep(3)} className="flex-1">
                      Next: Skills
                    </Button>
                  </div>
                </div>
              )}

              {step === 3 && (
                <div className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Skills
                    </label>
                    <div className="mt-1 flex gap-2">
                      <input
                        type="text"
                        value={skillInput}
                        onChange={(e) => setSkillInput(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && (e.preventDefault(), addSkill())}
                        className="flex-1 rounded-md border p-2"
                        placeholder="Add a skill..."
                      />
                      <Button type="button" onClick={addSkill}>
                        Add
                      </Button>
                    </div>
                    <div className="mt-2 flex flex-wrap gap-2">
                      {skills.map((skill) => (
                        <span
                          key={skill}
                          className="flex items-center gap-2 rounded-full bg-blue-100 px-3 py-1 text-sm text-blue-800"
                        >
                          {skill}
                          <button
                            type="button"
                            onClick={() => removeSkill(skill)}
                            className="text-blue-600 hover:text-blue-800"
                          >
                            ×
                          </button>
                        </span>
                      ))}
                    </div>
                  </div>

                  <div className="flex gap-4">
                    <Button type="button" variant="secondary" onClick={() => setStep(2)}>
                      Back
                    </Button>
                    <Button type="submit" className="flex-1">
                      Complete Profile
                    </Button>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}