import { ForumTopic } from '@/types/forum';

export const forumTopics: ForumTopic[] = [
  {
    id: '1',
    title: 'Best Practices for Fine-tuning LLMs on Domain-Specific Data',
    description: 'I\'m working on fine-tuning GPT models for medical text analysis. Looking for advice on handling domain-specific terminology and maintaining model performance while reducing hallucinations. Has anyone successfully implemented this in a production environment?',
    author: {
      name: 'Dr. Sarah Chen',
      avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=400',
    },
    category: 'llm',
    tags: ['fine-tuning', 'medical-ai', 'gpt', 'domain-adaptation'],
    replies: 45,
    likes: 123,
    lastActivity: '2 hours ago',
  },
  {
    id: '2',
    title: 'Implementing Real-time Object Detection in Edge Devices',
    description: 'Share your experiences with deploying computer vision models on edge devices. Currently testing YOLO v8 on Raspberry Pi 4, but facing some latency issues. Looking for optimization techniques and alternative model architectures.',
    author: {
      name: 'Michael Wong',
      avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=400',
    },
    category: 'computer-vision',
    tags: ['edge-ai', 'object-detection', 'yolo', 'optimization'],
    replies: 32,
    likes: 98,
    lastActivity: '5 hours ago',
  },
  {
    id: '3',
    title: 'Addressing Bias in AI Recruitment Systems',
    description: 'Important discussion on identifying and mitigating bias in AI-powered recruitment tools. Looking to share methodologies for fair candidate assessment and ensuring ethical AI practices in HR tech.',
    author: {
      name: 'Emily Johnson',
      avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=400',
    },
    category: 'ethics',
    tags: ['ai-ethics', 'recruitment', 'bias', 'fairness'],
    replies: 67,
    likes: 145,
    lastActivity: '1 day ago',
  },
  {
    id: '4',
    title: 'Advanced Prompt Engineering Techniques for GPT Models',
    description: 'Let\'s discuss advanced prompting strategies for better control and consistency in LLM outputs. Share your experiences with chain-of-thought prompting, few-shot learning, and structured output generation.',
    author: {
      name: 'Alex Martinez',
      avatar: 'https://images.unsplash.com/photo-1500648767791-00dcc994a43e?w=400',
    },
    category: 'llm',
    tags: ['prompt-engineering', 'gpt', 'nlp', 'best-practices'],
    replies: 89,
    likes: 234,
    lastActivity: '6 hours ago',
  },
  {
    id: '5',
    title: 'Building Robust MLOps Pipelines: Lessons Learned',
    description: 'Sharing insights from implementing MLOps at scale. Topics include: automated testing, continuous training, monitoring, and handling data drift. Would love to hear your experiences and challenges.',
    author: {
      name: 'David Park',
      avatar: 'https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?w=400',
    },
    category: 'mlops',
    tags: ['mlops', 'devops', 'automation', 'monitoring'],
    replies: 42,
    likes: 156,
    lastActivity: '1 day ago',
  }
];