import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import { Mail, CheckCircle, XCircle } from 'lucide-react';
import { Button } from '@/components/ui/button';
import toast from 'react-hot-toast';

export function EmailVerificationPage() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] = useState<'pending' | 'success' | 'error'>('pending');

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        const token = searchParams.get('token');
        if (!token) throw new Error('No verification token found');

        const { error } = await supabase.auth.verifyOtp({
          token_hash: token,
          type: 'email',
        });

        if (error) throw error;
        setVerificationStatus('success');
        setTimeout(() => navigate('/profile-setup'), 2000);
      } catch (error) {
        console.error('Verification error:', error);
        setVerificationStatus('error');
      }
    };

    verifyEmail();
  }, [searchParams, navigate]);

  const handleResendVerification = async () => {
    try {
      const { error } = await supabase.auth.resend({
        type: 'signup',
        email: searchParams.get('email') || '',
      });

      if (error) throw error;
      toast.success('Verification email sent!');
    } catch (error) {
      toast.error('Failed to send verification email');
    }
  };

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-50">
      <div className="w-full max-w-md rounded-lg border bg-white p-8 shadow-sm">
        <div className="text-center">
          {verificationStatus === 'pending' && (
            <>
              <Mail className="mx-auto h-12 w-12 text-blue-600" />
              <h1 className="mt-4 text-2xl font-bold">Verifying Your Email</h1>
              <p className="mt-2 text-gray-600">Please wait while we verify your email address...</p>
            </>
          )}

          {verificationStatus === 'success' && (
            <>
              <CheckCircle className="mx-auto h-12 w-12 text-green-600" />
              <h1 className="mt-4 text-2xl font-bold">Email Verified!</h1>
              <p className="mt-2 text-gray-600">
                Your email has been successfully verified. Redirecting to profile setup...
              </p>
            </>
          )}

          {verificationStatus === 'error' && (
            <>
              <XCircle className="mx-auto h-12 w-12 text-red-600" />
              <h1 className="mt-4 text-2xl font-bold">Verification Failed</h1>
              <p className="mt-2 text-gray-600">
                We couldn't verify your email. The link may have expired.
              </p>
              <Button
                onClick={handleResendVerification}
                className="mt-4"
              >
                Resend Verification Email
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}