import { Heart, MessageCircle, Share2, Bookmark } from 'lucide-react';
import { Button } from '@/components/ui/button';
import toast from 'react-hot-toast';
import type { NewsPostActionsProps } from './types';

export function NewsPostActions({
  likes,
  comments,
  isBookmarked,
  onLike,
  onComment,
  onShare,
  onBookmark,
}: NewsPostActionsProps) {
  const handleShare = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      toast.success('Link copied to clipboard!');
      onShare();
    } catch (error) {
      toast.error('Failed to copy link');
    }
  };

  return (
    <div className="flex items-center gap-6">
      <Button
        variant="ghost"
        size="sm"
        className="gap-2"
        onClick={onLike}
      >
        <Heart className={likes > 0 ? 'fill-red-500 text-red-500' : ''} />
        <span>{likes}</span>
      </Button>
      <Button
        variant="ghost"
        size="sm"
        className="gap-2"
        onClick={onComment}
      >
        <MessageCircle />
        <span>{comments}</span>
      </Button>
      <Button
        variant="ghost"
        size="sm"
        onClick={handleShare}
      >
        <Share2 />
      </Button>
      <Button
        variant="ghost"
        size="sm"
        onClick={onBookmark}
      >
        <Bookmark className={isBookmarked ? 'fill-current' : ''} />
      </Button>
    </div>
  );
}