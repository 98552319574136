import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from '../ui/button';
import { Plus, X } from 'lucide-react';
import toast from 'react-hot-toast';
import { ForumCategory } from '@/types/forum';

const createTopicSchema = z.object({
  title: z.string().min(10, 'Title must be at least 10 characters'),
  description: z.string().min(30, 'Description must be at least 30 characters'),
  category: z.string().min(1, 'Please select a category'),
});

type CreateTopicFormData = z.infer<typeof createTopicSchema>;

interface ForumCreateTopicProps {
  categories: ForumCategory[];
  onCreateTopic: (data: CreateTopicFormData & { tags: string[] }) => void;
}

export function ForumCreateTopic({ categories, onCreateTopic }: ForumCreateTopicProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [tags, setTags] = useState<string[]>([]);
  const [tagInput, setTagInput] = useState('');

  const { register, handleSubmit, formState: { errors }, reset } = useForm<CreateTopicFormData>({
    resolver: zodResolver(createTopicSchema),
  });

  const handleAddTag = () => {
    if (tagInput.trim() && !tags.includes(tagInput.trim())) {
      setTags([...tags, tagInput.trim()]);
      setTagInput('');
    }
  };

  const handleRemoveTag = (tagToRemove: string) => {
    setTags(tags.filter(tag => tag !== tagToRemove));
  };

  const onSubmit = (data: CreateTopicFormData) => {
    onCreateTopic({ ...data, tags });
    setIsOpen(false);
    reset();
    setTags([]);
    toast.success('Topic created successfully!');
  };

  if (!isOpen) {
    return (
      <Button onClick={() => setIsOpen(true)} className="w-full">
        <Plus size={20} className="mr-2" />
        Create New Topic
      </Button>
    );
  }

  return (
    <div className="rounded-lg border bg-white p-6 shadow-sm">
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">Title</label>
          <input
            {...register('title')}
            className="mt-1 w-full rounded-md border p-2 focus:border-blue-500 focus:outline-none focus:ring-1"
            placeholder="Enter topic title..."
          />
          {errors.title && (
            <p className="mt-1 text-sm text-red-600">{errors.title.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Description</label>
          <textarea
            {...register('description')}
            className="mt-1 h-32 w-full rounded-md border p-2 focus:border-blue-500 focus:outline-none focus:ring-1"
            placeholder="Describe your topic..."
          />
          {errors.description && (
            <p className="mt-1 text-sm text-red-600">{errors.description.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Category</label>
          <select
            {...register('category')}
            className="mt-1 w-full rounded-md border p-2 focus:border-blue-500 focus:outline-none focus:ring-1"
          >
            <option value="">Select a category</option>
            {categories.filter(cat => cat !== 'all').map(category => (
              <option key={category} value={category}>
                {category.replace('-', ' ').toUpperCase()}
              </option>
            ))}
          </select>
          {errors.category && (
            <p className="mt-1 text-sm text-red-600">{errors.category.message}</p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Tags</label>
          <div className="mt-1 flex gap-2">
            <input
              type="text"
              value={tagInput}
              onChange={(e) => setTagInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && (e.preventDefault(), handleAddTag())}
              className="flex-1 rounded-md border p-2 focus:border-blue-500 focus:outline-none focus:ring-1"
              placeholder="Add tags..."
            />
            <Button type="button" onClick={handleAddTag}>Add Tag</Button>
          </div>
          <div className="mt-2 flex flex-wrap gap-2">
            {tags.map(tag => (
              <span
                key={tag}
                className="flex items-center gap-1 rounded-full bg-blue-100 px-3 py-1 text-sm text-blue-800"
              >
                {tag}
                <button
                  type="button"
                  onClick={() => handleRemoveTag(tag)}
                  className="ml-1 rounded-full hover:text-blue-600"
                >
                  <X size={14} />
                </button>
              </span>
            ))}
          </div>
        </div>

        <div className="flex justify-end gap-4">
          <Button type="button" variant="secondary" onClick={() => setIsOpen(false)}>
            Cancel
          </Button>
          <Button type="submit">Create Topic</Button>
        </div>
      </form>
    </div>
  );
}