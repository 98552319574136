import { Link } from 'react-router-dom';
import { Brain, Home, MessageSquare, Search, Users, BookOpen, Newspaper } from 'lucide-react';
import { Button } from '../ui/button';
import { ThemeToggle } from './theme-toggle';
import { MessageDropdown } from '../navigation/messages/message-dropdown';
import { NotificationDropdown } from '../navigation/notifications/notification-dropdown';
import { ProfileDropdown } from '../navigation/profile/profile-dropdown';

export function Navbar() {
  return (
    <nav className="fixed top-0 z-50 w-full border-b border-white/20 bg-white/80 backdrop-blur-lg">
      <div className="container mx-auto flex h-16 items-center justify-between px-4">
        <div className="flex items-center gap-8">
          <Link to="/" className="text-xl font-bold">
            <span className="text-gradient">Connectianet</span>
          </Link>
          <div className="hidden md:flex md:items-center md:gap-6">
            <Link to="/" className="flex items-center gap-2 text-gray-600 transition-colors hover:text-primary">
              <Home size={20} />
              <span>Home</span>
            </Link>
            <Link to="/news" className="flex items-center gap-2 text-gray-600 transition-colors hover:text-primary">
              <Newspaper size={20} />
              <span>News</span>
            </Link>
            <Link to="/forum" className="flex items-center gap-2 text-gray-600 transition-colors hover:text-primary">
              <BookOpen size={20} />
              <span>Forum</span>
            </Link>
            <Link to="/jobs" className="flex items-center gap-2 text-gray-600 transition-colors hover:text-primary">
              <Users size={20} />
              <span>Jobs</span>
            </Link>
          </div>
        </div>

        <div className="flex items-center gap-4">
          <div className="relative hidden md:block">
            <Search className="absolute left-3 top-1/2 h-4 w-4 -translate-y-1/2 text-gray-400" />
            <input
              type="search"
              placeholder="Search..."
              className="h-10 rounded-full bg-white/50 pl-10 pr-4 transition-all focus:outline-none focus:ring-2 focus:ring-primary/20"
            />
          </div>
          <MessageDropdown />
          <NotificationDropdown />
          <ThemeToggle />
          <ProfileDropdown />
        </div>
      </div>
    </nav>
  );
}