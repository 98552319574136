import { NewsPost } from '@/types/news';

export const newsPosts: NewsPost[] = [
  {
    id: '1',
    title: 'GPT-5 Breakthrough: New Multimodal Capabilities Unveiled',
    content: `OpenAI has announced a major breakthrough with GPT-5, introducing unprecedented multimodal capabilities that mark a significant evolution in AI technology. The new model demonstrates remarkable abilities in processing and understanding visual, audio, and textual inputs simultaneously.`,
    author: {
      name: 'Sarah Chen',
      avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=400',
      headline: 'AI Research Lead at TechCorp',
    },
    category: 'industry-news',
    image: 'https://images.unsplash.com/photo-1620712943543-bcc4688e7485?w=800',
    likes: 342,
    comments: [],
    createdAt: new Date(Date.now() - 1000 * 60 * 60).toISOString(),
  },
  {
    id: '2',
    title: 'Breakthrough in Neural Network Architecture',
    content: `Researchers have developed a novel neural network architecture that significantly reduces computational requirements while maintaining high accuracy. This could revolutionize AI deployment on edge devices.`,
    author: {
      name: 'Michael Wong',
      avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=400',
      headline: 'Senior AI Researcher',
    },
    category: 'ai-research',
    likes: 256,
    comments: [],
    createdAt: new Date(Date.now() - 1000 * 60 * 120).toISOString(),
  }
];