import { useState } from 'react';
import { Bell } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { formatDistanceToNow } from '@/lib/utils';

interface Notification {
  id: string;
  type: 'mention' | 'like' | 'follow' | 'comment';
  content: string;
  timestamp: string;
  read: boolean;
}

export function NotificationDropdown() {
  const [isOpen, setIsOpen] = useState(false);
  const [notifications] = useState<Notification[]>([
    {
      id: '1',
      type: 'mention',
      content: 'David Kim mentioned you in a post about AI ethics',
      timestamp: new Date(Date.now() - 1000 * 60 * 30).toISOString(),
      read: false,
    },
    {
      id: '2',
      type: 'like',
      content: 'Your post about machine learning received 50 likes',
      timestamp: new Date(Date.now() - 1000 * 60 * 60).toISOString(),
      read: false,
    },
  ]);

  const unreadCount = notifications.filter(n => !n.read).length;

  return (
    <div className="relative">
      <Button
        variant="ghost"
        size="sm"
        className="relative"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Bell className="h-5 w-5" />
        {unreadCount > 0 && (
          <span className="absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-xs text-white">
            {unreadCount}
          </span>
        )}
      </Button>

      {isOpen && (
        <div className="absolute right-0 top-full mt-2 w-80 rounded-lg border bg-white p-4 shadow-lg">
          <div className="mb-4 flex items-center justify-between">
            <h3 className="font-semibold">Notifications</h3>
            {unreadCount > 0 && (
              <span className="text-sm text-gray-500">{unreadCount} new</span>
            )}
          </div>

          <div className="space-y-4">
            {notifications.map((notification) => (
              <div
                key={notification.id}
                className={`rounded-lg p-3 transition-colors ${
                  !notification.read ? 'bg-blue-50' : 'hover:bg-gray-50'
                }`}
              >
                <div className="flex items-start justify-between gap-2">
                  <p className="text-sm text-gray-600">{notification.content}</p>
                  <span className="flex-shrink-0 text-xs text-gray-500">
                    {formatDistanceToNow(new Date(notification.timestamp))}
                  </span>
                </div>
              </div>
            ))}
          </div>

          <Button
            variant="ghost"
            className="mt-4 w-full text-sm text-blue-600 hover:bg-blue-50"
          >
            View All Notifications
          </Button>
        </div>
      )}
    </div>
  );
}