import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Button } from '@/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import { useAuthStore } from '@/lib/store';
import toast from 'react-hot-toast';
import { JobType, ExperienceLevel } from '@/types/job';

const jobSchema = z.object({
  title: z.string().min(5, 'Job title must be at least 5 characters'),
  type: z.nativeEnum(JobType),
  level: z.nativeEnum(ExperienceLevel),
  location: z.string().min(2, 'Location is required'),
  salary: z.object({
    min: z.number().min(0),
    max: z.number().min(0),
    currency: z.string(),
  }),
  description: z.string().min(100, 'Description must be at least 100 characters'),
  requirements: z.array(z.string()).min(1, 'At least one requirement is required'),
  responsibilities: z.array(z.string()).min(1, 'At least one responsibility is required'),
  skills: z.array(z.string()).min(1, 'At least one skill is required'),
  benefits: z.array(z.string()),
  deadline: z.string().optional(),
});

type JobFormData = z.infer<typeof jobSchema>;

export function JobCreatePage() {
  const navigate = useNavigate();
  const user = useAuthStore((state) => state.user);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { register, handleSubmit, formState: { errors } } = useForm<JobFormData>({
    resolver: zodResolver(jobSchema),
    defaultValues: {
      type: JobType.FULL_TIME,
      level: ExperienceLevel.MID,
      salary: {
        currency: 'USD',
        min: 0,
        max: 0,
      },
      requirements: [''],
      responsibilities: [''],
      skills: [],
      benefits: [],
    },
  });

  const onSubmit = async (data: JobFormData) => {
    if (!user) {
      toast.error('You must be logged in to post a job');
      return;
    }

    try {
      setIsSubmitting(true);

      const { error } = await supabase
        .from('jobs')
        .insert({
          ...data,
          company_id: user.id,
          status: 'published',
          posted_at: new Date().toISOString(),
        });

      if (error) throw error;

      toast.success('Job posted successfully!');
      navigate('/jobs');
    } catch (error) {
      toast.error('Failed to post job');
      console.error('Error creating job:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      <div className="container mx-auto px-4 py-8">
        <div className="mx-auto max-w-3xl">
          <h1 className="mb-8 text-3xl font-bold">Post a New Job</h1>
          
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div className="rounded-lg border bg-white p-6 shadow-sm">
              {/* Basic Information */}
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Job Title</label>
                  <input
                    {...register('title')}
                    className="mt-1 w-full rounded-md border p-2"
                    placeholder="e.g. Senior AI Engineer"
                  />
                  {errors.title && (
                    <p className="mt-1 text-sm text-red-600">{errors.title.message}</p>
                  )}
                </div>

                <div className="grid gap-4 sm:grid-cols-2">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">Job Type</label>
                    <select
                      {...register('type')}
                      className="mt-1 w-full rounded-md border p-2"
                    >
                      {Object.values(JobType).map((type) => (
                        <option key={type} value={type}>
                          {type.replace('-', ' ').toUpperCase()}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label className="block text-sm font-medium text-gray-700">Experience Level</label>
                    <select
                      {...register('level')}
                      className="mt-1 w-full rounded-md border p-2"
                    >
                      {Object.values(ExperienceLevel).map((level) => (
                        <option key={level} value={level}>
                          {level.toUpperCase()}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-end gap-4">
              <Button
                type="button"
                variant="secondary"
                onClick={() => navigate('/jobs')}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Posting...' : 'Post Job'}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}