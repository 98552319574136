export const trendingTopics = [
  { rank: 1, name: 'GPT-5', posts: 1234 },
  { rank: 2, name: 'AI Ethics', posts: 856 },
  { rank: 3, name: 'Machine Learning', posts: 642 },
  { rank: 4, name: 'Computer Vision', posts: 531 },
  { rank: 5, name: 'Neural Networks', posts: 423 },
];

export const topContributors = [
  {
    name: 'Sarah Chen',
    avatar: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?w=400',
    contributions: 156,
  },
  {
    name: 'Michael Wong',
    avatar: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?w=400',
    contributions: 143,
  },
  {
    name: 'Emily Johnson',
    avatar: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?w=400',
    contributions: 128,
  },
];